/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 18px;

$baseLineHeight: 28px;

$h1Size: 32px;

$h2Size: 28px;

$h3Size: 24px;

$h4Size: 22px;

$h5Size: 18px;

$h6Size: 16px;

$mainFont: Lato;

$displayFont: Lato;

$iconFont: icomoon;

// Project colors

$primary: #bb3838;
$secondary: #000000;
$light: #fff;
$medium: #f5f5f5;
$dark: #000000;
$border: #d1d1d1;
$alert: #bb3838;

$baseGap: 18px;

$rowMaxWidth: 1400px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	minus: "-", 
	angle-right: "❯", 
	plus: "+", 
	angle-up: "↑", 
	exclamation: "!", 
	angle-down: "\e900", 
	car: "\e901", 
	envelope: "\e902", 
	mug: "\e903", 
	phone: "\e904", 
	wlan: "\e905", 
	shower: "\e906", 
	no-smoking: "\e907", 
	users: "\e908", 
	parking: "\e909", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
	),
	tiny: (
		width: em(480px, $bpContext), 
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (6),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (3, 4, 5, 6, 7),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (4, 6, 9),
		prefix: (3, 6),
		suffix: (6),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (5, 7),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (4, 8),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
	full: (
		width: em(1520px, $bpContext), 
	),
	max: (
		width: em(1700px, $bpContext), 
	),
);

