
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	margin-top: rem(80px);
	padding: rem(80px) 0;

	@include breakpoint(large) {
		padding: rem(150px) 0;
		margin-top: rem(200px);
	}

	@include breakpoint(giant) {
		padding: rem(180px) 0;
	}

	@include breakpoint(huge) {
		padding: rem(200px) 0;
	}

	body:not(.index) & {
		padding: rem(50px);

		@include breakpoint(large) {
			padding: rem(120px);
		}

		@include breakpoint(giant) {
			padding: rem(160px);
		}
	}

	@include responsiveImage('layout/bgHeader.jpg', (tiny, small, medium, large), true);

	&.lazyLoaded {
		background-size:cover
	}

	.branding {
		display: block;
		text-align: center;
		margin-bottom: 0;

		@include breakpoint(small) {
			display: inline-block;
			text-align: left;
		}

		body.index &, body.enIndex & {
			margin-bottom: rem(30px);
		}

		@include breakpoint(large) {
			display: none;
		}

		img {
			width: auto;
		}
	}

	p {
		color: $light;
		margin-bottom: rem(30px);
		font-weight: 900;
		text-transform: uppercase;
		font-size: rem(20px);
		line-height: rem(32px);
		text-align: center;
		display: none;

		body.index &, body.enIndex & {
			display: block;
		}

		@include breakpoint(small) {
			line-height: rem(35px);
			text-align: left;
		}

		@include breakpoint(medium) {
			line-height: rem(40px);
			margin-bottom: rem(40px);
		}

		@include breakpoint(large) {
			font-size: rem(25px);
			line-height: rem(48px);
		}

		span {
			text-transform: none;
			display: block;
			font-size: rem(22px);

			@include breakpoint(small) {
				font-size: rem(30px);
			}

			@include breakpoint(medium) {
				font-size: rem(35px);
			}

			@include breakpoint(large) {
				font-size: rem(50px);
			}
		}
	}

	.buttons {
		display: none;

		body.index &, body.enIndex & {
			display: block;

			@include breakpoint(small) {
				display: flex;
			}
		}

		.btn {
			&:first-child {
				margin-bottom: rem(10px);

				@include breakpoint(small) {
					margin-bottom: 0;
					margin-right: rem(20px);
				}
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	.highlight {
		margin-top: rem(100px);

		.success {
			background: $primary;
			padding: rem(40px);

			p {
				color: $light;
				text-align: center;
				margin-bottom: 0;
			}
		}
	}

	.contentContainerTop {
		padding: rem(80px) 0 0 0;

		@include breakpoint(medium) {
			padding: rem(100px) 0 0 0;
		}

		@include breakpoint(large) {
			padding: rem(120px) 0 0 0;
			align-items: center;
		}

		@include breakpoint(giant) {
			padding: rem(150px) 0 0 0;
		}

		@include breakpoint(huge) {
			padding: rem(180px) 0 0 0;
		}

		ul {
			li {
				border-bottom: rem(1px) solid $border;
				padding: rem(18px) 0 rem(18px) 0!important;
				margin-bottom: 0;
				text-align: left;
				font-size: rem(17px);

				@include breakpoint(tiny) {
					font-size: rem(18px);
				}

				&:before {
					text-indent: 0!important;
					margin-right: rem(10px);
				}

				&:first-child {
					&:before {
						font-family: $iconFont;
						content: map-get($iconMap, parking);
						font-size: rem(14px);
					}
				}

				&:nth-child(2) {
					&:before {
						font-family: $iconFont;
						content: map-get($iconMap, mug);
					}
				}

				&:nth-child(3) {
					&:before {
						font-family: $iconFont;
						content: map-get($iconMap, wlan);
					}
				}

				&:nth-child(4) {
					&:before {
						font-family: $iconFont;
						content: map-get($iconMap, car);
						font-size: rem(15px);
					}
				}

				&:nth-child(5) {
					&:before {
						font-family: $iconFont;
						content: map-get($iconMap, no-smoking);
					}
				}

				&:nth-child(6) {
					&:before {
						font-family: $iconFont;
						content: map-get($iconMap, users);
						font-size: rem(14px);
					}
				}

				&:last-child {
					border-bottom: none;

					&:before {
						display: none;
					}
				}
			}
		}
	}
	
	.contentContainer {
		padding: rem(80px) 0;

		@include breakpoint(medium) {
			padding: rem(100px) 0;
		}

		@include breakpoint(large) {
			padding: rem(120px) 0;
			align-items: center;
		}

		body.agb & {
			align-items: flex-start;

			ul {
				li {
					&:before {
						display: none;
					}
				}
			}
		}

		body.kontakt &, body.impressum & {
			h1 {
				margin-bottom: rem(50px)!important;
			}
		}

		body.anfahrt & {
			h1 {
				margin-bottom: rem(50px)!important;
			}

			.googleMaps {
				height: 100%;

				iframe {
					height: 100%;
				}
			}
		}

		body.impressum & {
			hr {
				margin: rem(50px) 0;
			}
		}

		body.aktuellesUndAngebote & {
			align-items: flex-start;

			h1 {
				margin-bottom: rem(50px)!important;
			}

			p {
				text-align: left;
			}

			hr {
				margin: rem(30px) 0;
			}
		}

		@include breakpoint(giant) {
			padding: rem(150px) 0;
		}

		@include breakpoint(huge) {
			padding: rem(180px) 0;
		}


		ul {
			li {
				border-bottom: rem(1px) solid $border;
				padding: rem(18px) 0 rem(18px) 0!important;
				margin-bottom: 0;
				text-align: left;
				font-size: rem(17px);

				&:last-child {
					border-bottom: none;
				}

				@include breakpoint(tiny) {
					font-size: rem(18px);
				}

				&:before {
					text-indent: 0!important;
					margin-right: rem(10px);
				}
			}
		}
	}

	.reiseInfos {
		padding: rem(80px) 0;

		@include breakpoint(medium) {
			padding: rem(100px) 0;
		}

		@include breakpoint(large) {
			padding: rem(120px) 0;
		}

		@include breakpoint(giant) {
			padding: rem(150px) 0;
		}

		@include breakpoint(huge) {
			padding: rem(180px) 0;
		}

		img {
			margin-top: rem(50px);

			@include breakpoint(medium) {
				margin-top: 0;
			}
		}

		ul {
			line-height: rem(30px);
			margin-bottom: 0;

			li {
				padding-left: 0;
				margin-bottom: 0;

				&:first-child {
					font-weight: 300;
					text-transform: uppercase;
				}

				&:before {
					display: none;
				}
			}
		}

		.reisezeit {
			border-bottom: rem(1px) solid $border;
			border-top: rem(1px) solid $border;
			padding: rem(25px) 0;

			.innerZeit {
				display: flex;
			}

			p {
				margin-bottom: 0;
				margin-top: rem(20px);
			}

			ul {
				&:first-child {
					margin-right: rem(40px);

					@include breakpoint(tiny) {
						margin-right: rem(80px);
					}

					@include breakpoint(giant) {
						margin-right: rem(120px);
					}
				}

				li {
					&:last-child {
						font-weight: 900;
						font-size: rem(18px);
					}
				}
			}
		}

		.address {
			border-bottom: rem(1px) solid $border;
			padding: rem(25px) 0;

			.mobileNone {
				display: none;

				@include breakpoint(tiny) {
					display: inline-block;
				}
			}
		}

		.contact {
			border-bottom: rem(1px) solid $border;
			padding: rem(25px) 0;

			@include breakpoint(tiny) {
				display: flex;
			}

			ul {
				&:first-child {
					margin-bottom: rem(20px);

					@include breakpoint(tiny) {
						margin-bottom: 0;
						margin-right: rem(40px);
					}

					@include breakpoint(medium) {
						margin-right: rem(20px);
					}

					@include breakpoint(large) {
						margin-right: rem(40px);
					}

					@include breakpoint(giant) {
						margin-right: rem(60px);
					}

					@include breakpoint(huge) {
						margin-right: rem(20px);
					}

					@include breakpoint(full) {
						margin-right: rem(38px);
					}
				}
			}

			.phone {
				&:before {
					font-family: $iconFont;
					content: map-get($iconMap, phone);
					font-size: rem(16px);
					margin-right: rem(10px);
				}
			}

			.email {
				text-decoration: none;

				&:before {
					font-family: $iconFont;
					content: map-get($iconMap, envelope);
					color: $dark;
					font-size: rem(16px);
					margin-right: rem(12px);
				}
			}
		}
	}

	.doppelzimmerTeaser {
		padding: 0 0 rem(80px) 0;

		@include breakpoint(medium) {
			padding: 0 0 rem(100px) 0;
		}

		@include breakpoint(large) {
			padding: rem(120px) 0;
			position: relative;
		}

		@include breakpoint(giant) {
			padding: rem(150px) 0;
		}

		.large-6 {
			@include breakpoint(huge) {
				padding-left: 0;
			}
		}

		&.isOnScreen {
			&:before {
				content: '';
				width: 100%;
				height: rem(350px);
				display: block;
				margin-bottom: rem(80px);
				background: url('/images/index/tiny-doppelzimmer.jpg') no-repeat;
				background-size: cover;

				@include breakpoint(tiny) {
					background: url('/images/index/small-doppelzimmer.jpg') no-repeat;
					background-size: cover;
				}

				@include breakpoint(small) {
					background: url('/images/index/medium-doppelzimmer.jpg') no-repeat;
					background-size: cover;
				}

				@include breakpoint(medium) {
					background: url('/images/index/large-doppelzimmer.jpg') no-repeat;
					background-size: cover;
					margin-bottom: rem(100px);
				}

				@include breakpoint(large) {
					background: url('/images/index/giant-doppelzimmer.jpg') no-repeat;
					background-size: cover;
					position: absolute;
					top: 0;
					width: 42%;
					height: 100%;
					left: 0;
					margin-bottom: 0;
				}

				@include breakpoint(giant) {
					background: url('/images/index/huge-doppelzimmer.jpg') no-repeat;
					background-size: cover;
				}

				@include breakpoint(huge) {
					background: url('/images/index/doppelzimmer.jpg') no-repeat;
					background-size: cover;
				}

				@include breakpoint(full) {
					width: 43%;
				}
			}
		}
	}


	.einzelzimmerTeaser {
		padding: rem(80px) 0 0 0;
		margin-bottom: rem(3px);

		@include breakpoint(medium) {
			padding: rem(100px) 0 0 0;
		}

		@include breakpoint(large) {
			padding: rem(120px) 0;
			position: relative;
		}

		@include breakpoint(giant) {
			padding: rem(150px) 0;
		}

		.large-6 {
			@include breakpoint(huge) {
				padding-right: 0;
			}
		}
		
		&.isOnScreen {
			&:after {
				content: '';
				width: 100%;
				height: rem(350px);
				display: block;
				margin-top: rem(80px);
				background: url('/images/index/tiny-einzelzimmer.jpg') no-repeat;
				background-size: cover;

				@include breakpoint(tiny) {
					background: url('/images/index/small-einzelzimmer.jpg') no-repeat;
					background-size: cover;
				}

				@include breakpoint(small) {
					background: url('/images/index/medium-einzelzimmer.jpg') no-repeat;
					background-size: cover;
				}

				@include breakpoint(medium) {
					background: url('/images/index/large-einzelzimmer.jpg') no-repeat;
					background-size: cover;
					margin-top: rem(100px);
				}

				@include breakpoint(large) {
					background: url('/images/index/giant-einzelzimmer.jpg') no-repeat;
					background-size: cover;
					position: absolute;
					top: 0;
					width: 42%;
					height: 100%;
					right: 0;
					margin-top: 0;
				}

				@include breakpoint(giant) {
					background: url('/images/index/huge-einzelzimmer.jpg') no-repeat;
					background-size: cover;
				}

				@include breakpoint(huge) {
					background: url('/images/index/einzelzimmer.jpg') no-repeat;
					background-size: cover;
				}

				@include breakpoint(full) {
					width: 43%;
				}
			}
		}
	}

	.doppelzimmerTeaser, .einzelzimmerTeaser {
		background: $medium;

		.headline {
			font-weight: 900;
			text-transform: uppercase;
			font-size: rem(20px);
			margin-bottom: rem(10px);

			@include breakpoint(tiny) {
				font-size: rem(25px);
				margin-bottom: rem(20px);
			}
		}

		.icons {
			@include breakpoint(full) {
				margin: rem(30px) 0 rem(40px) 0;
			}
			span {
				display: block;
				border-bottom: rem(1px) solid $border;
				padding: rem(20px) 0;

				@include breakpoint(small) {
					display: inline-block;
					border-bottom: none;
					padding: 0;
					font-size: rem(15px);
				}

				@include breakpoint(medium) {
					font-size: rem(16px);
				}

				@include breakpoint(large) {
					display: block;
					border-bottom: rem(1px) solid $border;
					padding: rem(20px) 0;
					font-size: rem(18px);
				}

				@include breakpoint(huge) {
					display: inline-block;
					border-bottom: none;
					padding: 0;
					font-size: rem(16px);
				}

				@include breakpoint(full) {
					font-size: rem(17px);
				}

				&:last-child {
					border-bottom: none;
				}

				&.trenner {
					display: none;

					@include breakpoint(small) {
						display: inline-block;
						color: #9d9d9d;
						margin: 0 rem(7px);
					}

					@include breakpoint(large) {
						display: none;
					}

					@include breakpoint(huge) {
						display: inline-block;
						color: #9d9d9d;
						margin: 0 rem(7px);
					}

					@include breakpoint(full) {
						margin: 0 rem(8px);
					}
				}

				&.nichtraucher {
					&:before {
						font-family: $iconFont;
						content: map-get($iconMap, no-smoking);
						margin-right: rem(12px);
					}
				}

				&.dusche {
					&:before {
						font-family: $iconFont;
						content: map-get($iconMap, shower);
						margin-right: rem(12px);
					}
				}

				&.wlan {
					&:before {
						font-family: $iconFont;
						content: map-get($iconMap, wlan);
						margin-right: rem(12px);
					}
				}

				&.m2 {
					&:before {
						content: 'm²';
						display: inline-block;
						font-weight: 900;
						margin-right: rem(12px);
					}
				}
			}
		}

		.buttons {
			@include breakpoint(small) {
				display: flex;
			}

			.btn {
				@include breakpoint(large) {
					padding: rem(20px) rem(35px);
				}

				@include breakpoint(giant) {
					padding: rem(20px) rem(50px);
				}

				&:first-child {
					margin-bottom: rem(10px);

					@include breakpoint(small) {
						margin-bottom: 0;
						margin-right: rem(20px);
					}
				} 
			}
		}
	}

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
		hyphens: auto;

		@include breakpoint(large) {
			text-align: justify;

			body.chrome & {
				text-align: left;
			}
		}
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	body.enIndex & {
		margin-top: rem(80px);
		display: block;

		@include breakpoint(medium) {
			margin-top: rem(100px);
		}

		@include breakpoint(large) {
			margin-top: rem(120px);
		}
		
		@include breakpoint(giant) {
			margin-top: rem(150px);
		}

		@include breakpoint(huge) {
			margin-top: rem(180px);
		}
	}

	.claimContainer {
		padding: rem(80px) 0;
		text-align: center;
		@include responsiveImage('layout/footerBg.jpg', (tiny, small, medium, large), true);

		@include breakpoint(medium) {
			padding: rem(100px) 0;
		}

		@include breakpoint(large) {
			padding: rem(120px) 0;
		}
		
		&.lazyLoaded {
			background-size: cover;
		}


		p {
			color: $light;
			margin-bottom: rem(30px);
			font-weight: 900;
			text-transform: uppercase;
			font-size: rem(20px);
			line-height: rem(32px);

			@include breakpoint(small) {
				line-height: rem(35px);
			}

			@include breakpoint(medium) {
				line-height: rem(40px);
				margin-bottom: rem(50px);
			}

			@include breakpoint(large) {
				font-size: rem(25px);
				line-height: rem(55px);
			}

			span {
				text-transform: none;
				display: block;
				font-size: rem(22px);

				@include breakpoint(small) {
					font-size: rem(30px);
				}

				@include breakpoint(medium) {
					font-size: rem(40px);
				}

				@include breakpoint(large) {
					font-size: rem(50px);
				}
			}
		}

		.buttons {
			@include breakpoint(small) {
				display: flex;
				justify-content: center;
			}

			.btn {
				&:first-child {
					margin-bottom: rem(10px);

					@include breakpoint(small) {
						margin-bottom: 0;
						margin-right: rem(20px);
					}
				}
			}
		}
	}


	.googleMaps {
		padding-bottom: 0!important;
		height: rem(400px);

		body.anfahrt & {
			display: none;
		}

		@include breakpoint(giant) {
			height: rem(660px);
		}

		iframe {
			height: rem(400px);



			@include breakpoint(giant) {
				height: rem(660px);
			}
		}
	}
}