// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	// Link Hover
	color: $primary;
}
%buttonActive {
	// Link Active
	color: $primary;
	font-weight: 700;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 80px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	width: 100%;

	.branding {
		display: none;
	}
	
	.topLine {
		padding: rem(25px) rem(46px);
		border-bottom: rem(1px) solid $border;

		.socials {
			display: none;
		}

		.contact {
			display: none;
		}

		.languages {
			display: flex;
			height: rem(22px);

			li {
				width: rem(36px);
				margin-bottom: 0;

				&.de {
					margin-right: rem(13px);
				}

				a {
					&:hover, &.active {
						opacity: .5;
					}
				}

				.de {
					text-indent: rem(-9999px);
					position: relative;
					display: block;

					&:before {
						content: '';
						background: url('/images/layout/de.jpg') no-repeat;
						height: rem(22px);
						width: rem(36px);
						display: inline-block;
						position: absolute;
						left: 0;
						top: 0;
					}
				}

				.en {
					text-indent: rem(-9999px);
					position: relative;
					display: block;

					&:before {
						content: '';
						background: url('/images/layout/en.jpg') no-repeat;
						height: rem(22px);
						width: rem(36px);
						display: inline-block;
						position: absolute;
						left: 0;
						top: 0;
					}
				}
			}
		}
	}

	.col {
		padding: 0;
	}

	nav {
		@include ul {
			padding-bottom: rem(150px);

			&.navi {
				li {
					border-bottom: rem(1px) solid $border;
					padding: rem(25px) rem(35px);

					&:last-child {
						border-bottom: none;
					}

					@include navButtons {
						@include navButton;
						padding: 0;
						font-weight: 300;
					}

					&.agb, &.newsletter, &.anfahrt, &.impressum, &.datenschutzerklaerung, &.map, &.legalNotice, &.privacyPolicy {
						display: none;
					}

					img {
						width: auto;
						transition: .5s all;

						&:hover {
							opacity: .5;
						}
					}
						
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
								text-align: center;
								text-transform: uppercase;
								font-weight: 900;
								font-size: rem(20px);
							}
						}
					}
					@include navBack {
						font-weight: 700;
						font-size: rem(16px);
						padding: rem(10px) rem(30px);
						transition: .2s all;
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		box-shadow: 0 0 rem(30px) rem(2px) rgba(0,0,0,0.2);

		&:before {
			content: '';
			background: $medium;
			height: 100%;
			width: 20%;
			position: absolute;
			left: 0;
			top: 0;
		}


		.branding {
			display: block;
			position: absolute;
			left: 1rem;
			top: rem(30px);
			z-index: 5;

			img {
				width: auto;
			}
		}

		.topLine {
			padding: rem(20px) 0;

			.flexLine {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.contact {
				display: flex;
				font-size: rem(14px);

				.big {
					text-transform: uppercase;
				}

				.mail {
					&:before {
						font-family: $iconFont;
						content: map-get($iconMap, envelope);
						margin-right: rem(5px);
					}
				}

				.tel {
					&:before {
						font-family: $iconFont;
						content: map-get($iconMap, phone);
						margin-right: rem(5px);
					}
				}

				.email {
					margin-right: rem(10px);
				}
			}

			.socials {
				display: flex;

				a {
					&:first-child {
						margin-right: rem(15px);
					}
				}

				img {
					width: auto;
					transition: .5s all;

					&:hover {
						opacity: .5;
					}
				}
			}
		}

		.col {
			padding: 0 1rem;
		}

		nav {
			padding: rem(50px) 0;
			ul {
				padding-bottom: 0;

				&.navi {
					li {
						&.directions, &.privacyPolicy, &.map, &.legalNotice, &:last-child, &:nth-child(13), &:nth-child(10) {
							display: none;
						}

						padding: 0;
						border-bottom: none;
						font-size: rem(16px);

						a {
							padding: 0;
						}

						&.hasSub {
							.sub {
								li {
									border-bottom: rem(1px) solid $border;
									padding: rem(25px);

									&:last-child {
										border-bottom: none;
										display: block!important;
									}
								}
							}
						}
						&.navBack {
						}
					}
				}
			}
		}
	}
}

@include breakpoint(giant) {
	@include desktopVersionFunctionality;

	#navigation {
		.branding {
			top: rem(50px);
		}

		.topLine {
			padding: 0;

			.contact {
				font-size: rem(16px);

				.mail {
					&:before {
						margin-right: rem(5px);
					}
				}

				.tel {
					&:before {
						margin-right: rem(5px);
					}
				}

				.email {
					margin-right: rem(20px);
				}
			}

			.socials {
				border-left: rem(1px) solid $border;
				border-right: rem(1px) solid $border;
				height: 100%;
				padding: rem(20px) rem(35px);

				a {
					&:first-child {
						margin-right: rem(15px);
					}
				}
			}
		}

		nav {
			padding: rem(50px) 0;

			ul {
				&.navi {
					align-items: center;

					li {
						padding: 0;
						border-bottom: none;
						font-size: rem(18px);

						a {
							padding: 0;

							&.jetztBuchen, &.bookNow {
								background: $primary;
								color: $light;
								text-transform: uppercase;
								padding: rem(20px) rem(25px);

								&:hover {
									background: darken($primary, 10%);
									color: $light;
								}

								&.active {
									background: darken($primary, 10%);
									color: $light;
									font-weight: 300;
								}
							}
						}

						&.hasSub {
							.sub {
							}
						}
						&.navBack {
						}
					}
				}
			}
		}
	}
}

@include breakpoint(huge) {
	@include desktopVersionFunctionality;

	#navigation {
		.branding {
			top: rem(50px);
		}

		.topLine {
			padding: 0;

			.contact {
				font-size: rem(18px);

				.mail {
					&:before {
						margin-right: rem(8px);
					}
				}

				.tel {
					&:before {
						margin-right: rem(8px);
					}
				}

				.email {
					margin-right: rem(30px);
				}
			}

			.socials {
				padding: rem(20px) rem(45px);
			}
		}

		nav {
			ul {
				&.navi {
					li {
						a {
							&.jetztBuchen, &.bookNow {
								padding: rem(20px) rem(50px);
							}
						}
					}
				}
			}
		}
	}
}

@include breakpoint(full) {
	@include desktopVersionFunctionality;

	#navigation {
		.topLine {
			.socials {
				padding: rem(20px) rem(55px);
			}
		}

		nav {
			ul {
				&.navi {
					li {
						a {
							&.jetztBuchen, &.bookNow {
								padding: rem(20px) rem(50px);
							}
						}
					}
				}
			}
		}
	}
}

@include breakpoint(max) {
	@include desktopVersionFunctionality;

	#navigation {
		&:before {
			width: 26%;
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	.phone {
		&:before {
			font-family: $iconFont;
			content: map-get($iconMap, phone);
			margin-right: rem(5px);
		}
	}

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	display: flex;
	flex-flow: column;
	justify-content: center;
	text-align: center;
	padding-bottom: rem(80px);

	@include breakpoint(small) {
		flex-flow: row;
		padding: rem(30px) 0 rem(100px) 0;
	}

	@include breakpoint(medium) {
		padding: rem(30px) 0;
	}

	li {
		border-bottom: rem(1px) solid $border;
		padding: rem(25px);

		@include breakpoint(small) {
			border-bottom: none;
			padding: 0;
			margin-right: rem(25px)
		}

		@include breakpoint(medium) {
			margin-right: rem(35px)
		}

		@include breakpoint(large) {
			margin-right: rem(45px)
		}

		&:last-child {
			border-bottom: none;

			@include breakpoint(small){
				margin-right: 0;
			}
		}

		a {
			color: $dark;
			text-decoration: none;
			font-weight: 300;

			&:hover {
				color: $primary;
			}

			&.active {
				color: $primary;
				font-weight: 700;
			}
		}
	}
}