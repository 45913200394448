// * Usage: @include pie-clearfix();
@mixin pie-clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  zoom: 1;
}

// * Hides text in block or inline-block elements
// * Usage: @include hideText();
// * $hideTextDirection is defined in _config.scss
@mixin hideText($direction: $hideTextDirection) {
  @if $direction == left {
    $wider-than-any-screen: -9999em;
    text-indent: $wider-than-any-screen;
    overflow: hidden;
    text-align: left;
  } @else {
    text-indent: 110%;
    white-space: nowrap;
    overflow: hidden;
  }
}

// * Appends the relative path to the images folder to the given URL
// * Usage: background-image: image-url("bg-body.jpg")
// * $imagePath is defined in _config.scss
@function image-url($url) {
  @return url("#{$imagePath}#{$url}");
}

// Grid Calculation in percentage
@function grid-size($size: 12, $columnCount: 12) {
  @return 100% / $columnCount * $size;
}

// NEW--------------------------------------------------------------------->
// Photoshop letter Tracking -> CSS Letter Spacing
@function ls($ps-value) {
  @return $ps-value / 1000 * 16px;
}

// All in one input placeholder styling
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

%lazyloading {
  background: url('/images/layout/loading.svg') no-repeat center center;
}

@mixin responsiveImage($imageName, $breakpoints, $lazyloading) {
    $fileType: "";
    @if str-index($imageName, '/images') == null {
      @if str-index($imageName, '/') != 1 {
        $imageName: '/images/#{$imageName}';
      } @else {
        $imageName: '/images#{$imageName}';
      }
    }
    $tempName: $imageName;
    $position: get_last_occurrence($imageName, '/');
    
    @if $lazyloading == true {
        @extend %lazyloading;

        &.lazyLoaded {
            background-image: url('#{$imageName}');
        }
    } @else {
        background-image: url('#{$imageName}');
    }
    @for $i from length($breakpoints)*-1 through -1 {
        $ci: nth($breakpoints, abs($i));
        @include breakpoint($ci, max) {
          @if $lazyloading == true {
            &.lazyLoaded {
                background-image: url('#{str_insert($imageName, #{$ci}-, $position + 1)}');
            }    
            } @else {      
                background-image: url('#{str_insert($imageName, #{$ci}-, $position + 1)}');
            }
        } 
    }
}
@function get_last_occurrence($string, $search) {

  $temp: $string;

  @while str-index($temp, $search) != null {
    $current: str-index($temp, $search);
    $temp: str-slice($temp, (str-length($temp)-$current) * -1, -1);
  }
  $position: str-length($string) - str-length($temp);

  @return $position;
}
@function toString($value) {
  @return inspect($value);
}